import { shuffle } from '~helper/index'
import { api } from '~services/index'

export const sharedApi = api.injectEndpoints({
    endpoints: (builder) => ({

        artists: builder.query({
            query: (body) => ({
                url: `/api/user/artists`,
                method: 'PATCH',
                body,
            }),
            serializeQueryArgs: ({ endpointName, queryArgs }) => {
                if (!queryArgs?.page)
                    return endpointName + `(${JSON.stringify(queryArgs)})`
                return endpointName
            },
            transformResponse: (
                response: any,
                _: any,
                arg: any,
            ) => {

                const {
                    page
                } = arg

                if (!page)
                    return response


                let shuffledData
                    = shuffle(
                        response?.data
                    )

                shuffledData
                    = shuffledData.sort((
                        obj1: any,
                        obj2: any
                    ) => {

                        let condition
                            = obj1
                                ?.artistRating

                            <
                            obj2
                                ?.artistRating


                        return condition ?
                            1
                            :
                            -1
                    })


                return {
                    ...response,
                    data: shuffledData,
                }
            },
            merge: (
                prevData,
                newData,
                { arg }
            ) => {

                const {
                    page = 1,
                } = arg || {}

                const {
                    data,
                    totalCount,
                } = newData

                if (page == 1)
                    return {
                        ...newData,
                        recentPage: page,
                    }
                else {

                    let mergedData
                        = [
                            ...prevData?.data,
                            ...data,
                        ]


                    return {
                        totalCount,
                        data: mergedData,
                        recentPage: page,
                    }
                }

            },
            forceRefetch({
                currentArg,
                previousArg,
                endpointState
            }: any) {

                const {
                    totalCount,
                } = endpointState?.data || {}

                const pages =
                    Math.ceil(
                        totalCount / 10
                    )

                const {
                    page
                } = currentArg

                console.log("forceRefetch page===>", page)

                if (
                    page > pages
                    &&
                    pages != 0
                )
                    return false

                return currentArg !== previousArg
            },
            providesTags: ['Artist'],
        }),
        colArtists: builder.query({
            query: (body) => ({
                url: `/api/user/artists`,
                method: 'PATCH',
                body,
            }),
            serializeQueryArgs: ({
                endpointName
            }) => {
                return endpointName
            },
            transformResponse: (
                response: any,
                _: any,
                arg: any,
            ) => {

                const {
                    page
                } = arg

                let shuffledData
                    = shuffle(
                        response?.data
                    )

                shuffledData
                    = shuffledData.sort((
                        obj1: any,
                        obj2: any
                    ) => {

                        let condition
                            = obj1
                                ?.artistRating
                            <
                            obj2
                                ?.artistRating

                        return condition ?
                            1
                            :
                            -1
                    })

                let columns: any = [
                    [],
                    [],
                    []
                ]

                let i = 0

                while (
                    shuffledData.length > 0
                ) {

                    let artist =
                        shuffledData.shift()
                    columns[i % 3].push(artist)
                    i++
                }

                let [
                    col3,
                    col1,
                    col2,
                ] = columns

                let data: any

                if (
                    page % 3
                    == 0
                )
                    data = [
                        col1,
                        col2,
                        col3,
                    ]
                else if (
                    page % 3
                    == 1
                )
                    data = [
                        col1,
                        col3,
                        col2,
                    ]
                else
                    data = [
                        col3,
                        col1,
                        col2,
                    ]

                return {
                    ...response,
                    data,
                }
            },
            merge: (
                prevData: any,
                newData: any,
                { arg }
            ) => {

                const {
                    page = 0,
                } = arg || {}

                const {
                    data,
                    totalCount,
                } = newData

                if (page == 1)
                    return {
                        ...newData,
                        recentPage: page,
                    }
                else {

                    let mergedData
                        = [
                            [
                                ...prevData?.data[0],
                                ...data[0],
                            ],
                            [
                                ...prevData?.data[1],
                                ...data[1],
                            ],
                            [
                                ...prevData?.data[2],
                                ...data[2],
                            ],
                        ]

                    return {
                        totalCount,
                        data: mergedData,
                        recentPage: page,
                    }
                }

            },
            forceRefetch({
                currentArg,
                previousArg,
                endpointState
            }: any) {

                const {
                    totalCount,
                } = endpointState?.data || {}

                const pages =
                    Math.ceil(
                        totalCount / 10
                    )

                const {
                    page
                } = currentArg

                if (
                    page > pages
                    &&
                    pages != 0
                )
                    return false

                return currentArg !== previousArg
            },
            providesTags: ['Artist'],
        }),
        artistDetails: builder.query({
            query: ({ _id }) => ({
                url: `/api/user/artist-details/${_id}`,
            }),
            providesTags: ['Artist'],
        }),

    }),
})

export const {
    useArtistsQuery,
    useColArtistsQuery,
    useArtistDetailsQuery,
} = sharedApi