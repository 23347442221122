import { Box, useTheme } from "@mui/system"
import RightSlider from 'public/assets/icons/right-slider.svg'
import LeftSlider from 'public/assets/icons/left-slider.svg'
import { forwardRef } from "react"

type SliderButtonsype = {
}

function SliderButtons(
    props: SliderButtonsype,
    ref: any,
) {

    const {
        palette: {
            text, common
        }
    }: any = useTheme()

    const scroll = (scrollOffset: any) => {
        ref.current.scrollLeft += scrollOffset;
    }

    return (
        <>
            <Box
                onClick={() => scroll(-400)}
                sx={{
                    position: 'absolute',
                    left: 30,
                    top: '50%',
                    transform: 'translateY(-50%)',
                    border: '0px solid red',
                    cursor: 'pointer',
                    '&:hover': {
                        backgroundColor: text.primary,
                    },
                    borderRadius: '10px',
                    backgroundColor: '#94999f',
                    color: common.white,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
                width={36}
                height={36}
            >
                <LeftSlider />
            </Box>
            <Box
                onClick={() => scroll(400)}
                sx={{
                    position: 'absolute',
                    right: 30,
                    top: '50%',
                    transform: 'translateY(-50%)',
                    border: '0px solid red',
                    cursor: 'pointer',
                    '&:hover': {
                        backgroundColor: text.primary,
                    },
                    borderRadius: '10px',
                    backgroundColor: '#94999f',
                    color: common.white,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
                width={36}
                height={36}
            >
                <RightSlider />
            </Box>
        </>
    )
}

export default forwardRef(SliderButtons)