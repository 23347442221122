import Box from '@mui/material/Box';
import PlayBtnBox from 'public/assets/icons/play-btn-box.svg';

type CardProps = {
  bgImg: string;
  margin: string;
  height: any;
  borderRadius?: any;
  sx?: object,
  onClick?: (() => void) | false | undefined | null
}

function SectionCard({
  bgImg,
  margin,
  height,
  borderRadius,
  sx,
  onClick
}: CardProps) {

  const handleOnClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <Box
      onClick={handleOnClick}
      sx={{
        ... (onClick ? { cursor: 'pointer' } : {}),
        backgroundImage: `url(${bgImg}) `,
        border: '0px solid red',
        borderRadius: borderRadius ? borderRadius : 5,
        margin: margin,
        height: {
          lg: height,
          xs: '350px',
        },
        backgroundSize: 'cover',
        width: {
          lg: '100%',
          xs: '343px',
        },
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        ...sx,
      }}
    >
      {
        onClick && <PlayBtnBox
          width={'100px'}
          height={'100px'}
        />
      }
    </Box >
  );
}

export default SectionCard;
