const searchTimeout = 500
const deliveryDays = 4
const deliveryDaysOption = 1
const deliveryDaysStart = deliveryDays - (deliveryDaysOption-1)


export {
    deliveryDays,
    searchTimeout,
    deliveryDaysStart,
    deliveryDaysOption,
}