import { yupResolver } from "@hookform/resolvers/yup"
import { object, boolean, string } from "yup"
import {
    string_validator
} from "~config/validators"

const forWhoSchema = object().shape({
    recipientName: string_validator("Name"),
    mentionRecipientInSong: boolean(),
    recipientPronounce: string(),
})

export default yupResolver(forWhoSchema)