import {
  yupResolver
} from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import {
  LoadingButton
} from '@mui/lab';
import {
  Alert,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
  useTheme
} from '@mui/material';
import {
  Box
} from '@mui/system';
import {
  toast
} from 'react-hot-toast'
import {
  AdapterDayjs
} from '@mui/x-date-pickers/AdapterDayjs';
import {
  DatePicker
} from '@mui/x-date-pickers/DatePicker';
import {
  LocalizationProvider
} from '@mui/x-date-pickers/LocalizationProvider';
import ModalBack from 'public/assets/icons/modal-back.svg';
import React from 'react';
import {
  Controller,
  useForm
} from 'react-hook-form';
import * as Yup from 'yup';
import FormProvider, {
  RHFTextField
} from '~components/hook-form';
import Image from '~components/image/Image';
import useResponsive from '~hooks/useResponsive';

import {
  useCreateSongReminderMutation, useGetOccasionsQuery
} from '../../services/reminderApi'
import { IMAGES_PREFIX } from "~config/config-global"
import { useSelector } from 'react-redux';

interface Props {
  onReset: any;
  occasionsList: any;
}


function ReminderContent({
  onReset,
  occasionsList
}: Props) {

  const {
    palette: {
      mode,
      text,
    }
  }: any = useTheme();

  const isMobile = useResponsive('down', 'sm')
  const isMd = useResponsive('down', 'md')


  const [
    createSongReminder,
    { isLoading }
  ] = useCreateSongReminderMutation()

  //   const { user } = useSelector(( state: any ) => state.auth)

  type FormValuesProps = {
    name: string;
    email: string;
    occasionDate: Date | null;
    occasion: string;
    afterSubmit?: string;
  };

  const CreateReminderSchema = Yup.object().shape({
    name: Yup.string().required('First name required'),
    email: Yup.string().required('Email is required').email('Email must be a valid email address'),
    occasionDate: Yup.date().required('Occation Date is required'),
    occasion: Yup.string().required('Occasion is required'),
  });

  const defaultValues = {
    name: '',
    email: '',
    occasionDate: null,
    occasion: '',
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(CreateReminderSchema),
    defaultValues,
  });

  const {
    reset,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = methods;

  const onSubmit = async (data: FormValuesProps) => {
    try {

      console.log(data)
      // let userId = user?.userDto?._id

      const response = await createSongReminder({
        ...data,
        occasionDate: dayjs(data.occasionDate).format('YYYY-MM-DD'),
        //  userId: userId || undefined,
      })

      const { error, data: respData }: any = response || {}

      if (error)
        toast.error(
          error
            ?.data
            ?.message
        )

      if (respData) {

        toast.success("Reminder created")
        reset()
        onReset()
      }

    } catch (error) {
      console.error(error);
      reset();
      setError('afterSubmit', {
        ...error,
        message: error.message || error,
      });
    }
  };




  return (
    <Grid container sx={{ display: 'flex' }}>
      <Grid
        sx={{
          display: {
            xs: 'none',
            md: 'block',
          },
        }}
        item
        md={5}
        xs={12}
        margin={0}
        padding={0}
      >
        <Image
          disabledEffect
          visibleByDefault
          alt="auth"
          src={IMAGES_PREFIX + '/assets/images/home/reminder.png'}
          imageStyles={{
            objectFit: 'cover',
          }}
          sx={{
            border: '0px solid red',
            borderRadius: ' 0px 0px 00px 0px',
            margin: '0',
            padding: '0',
          }}
        />
      </Grid>
      <Grid item md={7} xs={12} padding={3}>
        <Typography
          variant="h4"
          sx={{
            mb: isMd ?
              4
              :
              undefined,
          }}
        >
          {isMd &&
            <ModalBack
              onClick={onReset}
              style={{
                display: 'inline-block',
                marginRight: 15,
                marginBottom: 3,
              }}
            />
          }
          Remind me
        </Typography>
        <Box marginTop={1} marginBottom={2}>
          <Typography
            fontWeight="300"
            fontSize="16px"
            sx={{
              color: text.black
            }}
          >
            A song is like a fine wine, it only gets better with time. So why not order now and
            enjoy it for months to come? But if you're still hesitant, don't fret - just send a
            reminder to yourself and let future thank you for being so thoughtful.
          </Typography>
        </Box>
        <Typography
          variant="h5"
          fontWeight="500"
          fontSize="16px"
          marginBottom={2}
          sx={{
            mt: 4,
          }}
        >
          Who's the song for?
        </Typography>
        <FormProvider methods={methods}>


          <Stack spacing={2.5}>
            {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}

            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <RHFTextField name="name" label="First name" />
            </Stack>

            <RHFTextField name="email" label="Your Email address" />



            <Stack spacing={2}>

              <Controller
                name="occasionDate"
                control={methods.control}
                render={({ field, fieldState: { error } }: any) => (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      {...field}
                      label="Occasion Date"
                      inputProps={{
                        error: !!error,
                        helperText: error?.message,
                      }}
                    />
                  </LocalizationProvider>
                )}
              />
            </Stack>


            <Stack spacing={2}>
              <Controller
                name="occasion"
                control={methods.control}
                render={({ field: { onChange, value } }: any) => (
                  <FormControl variant="outlined" >
                    <InputLabel id="demo-simple-select-outlined-label">Occasion</InputLabel>
                    <Select

                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={value}
                      onChange={onChange}
                      label="Occassions"
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {
                        occasionsList.map(
                          ({ _id, name }: { _id: string, name: string }) =>
                            <MenuItem value={_id}>{name}</MenuItem>
                        )
                      }
                    </Select>
                  </FormControl>
                )}
              />
            </Stack>



          </Stack>
          <Box
            display="flex"
            marginTop={5}
            marginBottom={5}
            justifyContent="flex-end"
            columnGap={2}
            sx={{
              justifyContent: {
                md: 'flex-end',
                xs: 'center',
              },
              flexDirection: {
                md: 'row',
                xs: 'column-reverse',
              },
              rowGap: 2,
            }}
          >
            <LoadingButton
              color="inherit"
              size="medium"
              type="button"
              variant={isMobile ? 'text' : 'outlined'}
              onClick={onReset}
              sx={{
                padding: "8px 16px",
              }}
              loading={isSubmitting}
            >
              Cancel
            </LoadingButton>{' '}
            <LoadingButton
              onClick={handleSubmit(onSubmit)}
              color="inherit"
              size="medium"
              type="button"
              variant="contained"
              loading={isSubmitting}
              sx={{
                padding: "8px 16px",
                bgcolor: 'primary.main',
                color: (theme) => (mode === 'light' ?
                  'common.white'
                  :
                  'grey.800'),
                '&:hover': {
                  color: (theme) =>
                    mode === 'light' ?
                      'common.white'
                      :
                      'grey.800',
                },
              }}
            >
              Remind me
            </LoadingButton>
          </Box>
        </FormProvider>
      </Grid>
    </Grid>
  );
}

export default ReminderContent