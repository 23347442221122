import React from 'react';
import Box from '@mui/material/Box';

const VideoComponent = ({ src, className, videoStyles, sx, alt }: any) => {
    return (
        <Box
            className={className}
            sx={{
                position: 'relative',
                overflow: 'hidden',
                ...sx,
                '& video': {
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    ...videoStyles,
                }
            }}
        >
            <video src={src}
                // controls
                autoPlay
                loop
                muted // Muted is often required for autoplay to work in many browsers
                playsInline // This helps with autoplay on iOS devices
                style={videoStyles}>
                {alt} <br />
                Your browser does not support the video tag.
            </video>
        </Box>
    );
};

export default VideoComponent;
