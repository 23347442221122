import {
    Box,
    Typography,
    useTheme
} from '@mui/material';
import TimeOff from 'public/assets/icons/time-off.svg';
import Tooltip from '~components/tooltip';
import {
    busyArtist
} from '~config/constants/texts';

function TakingTimeOff({
    sx,
}: any) {

    const {
        palette: {
            primary,
            background
        }
    }: any = useTheme()

    return (
        <Tooltip
            message={busyArtist}
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: 24,
                    left: 24,
                    borderRadius: 16,
                    border: '0px solid red',
                    px: '12px',
                    py: '4px',
                    background:
                        background?.manish + "88",
                    display: 'flex',
                    ...sx,
                }}
            >
                <TimeOff />
                <Typography
                    variant='subtitle1'
                    sx={{
                        color:
                            primary
                                .contrastText,
                        fontWeight: 100,
                        ml: '8px',
                    }}
                >
                    Taking Time Off
                </Typography>
            </Box>
        </Tooltip>
    )
}

export default TakingTimeOff