import { api } from '~services/index'

export const reminderApi = api.injectEndpoints({
    endpoints: (builder) => ({

        createSongReminder: builder.mutation({
            query: (body) => {
                return {
                    url: `/api/song/song-reminder`,
                    method: 'POST',
                    body,
                }
            },
        }),


        getOccasions: builder.query({
            query: () => {
                return {
                    url: `/api/occasion/list-all`,
                    method: 'GET',
                }
            },
        }),

    }),
})

export const {
    useCreateSongReminderMutation,
    useGetOccasionsQuery,
} = reminderApi