import {
  Grid,
  Typography,
  useTheme
} from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Iconify from '~components/iconify/Iconify';
import { _faqs } from '~mock/arrays';
function FAQs() {
  const {
    palette: {
      primary,
      text,
    },
  }: any = useTheme();

  const renderFAQs = _faqs.map(({
    id,
    summary,
    details
  }: any) => (
    <Accordion
      key={id}
      sx={{
        border: '0px solid red',
        p: 0
      }}
    >
      <AccordionSummary
        sx={{
          border: '0px solid red',
          pt: 1
        }}
        expandIcon={
          <Iconify
            style={{ display: 'inline-block' }}
            icon="eva:chevron-down-fill"
            width={16}
          />
        }
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography
          fontSize="18px"
          fontWeight="500"
        >
          {summary}
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          border:
            '0px solid red',
          pt: 3,
        }}
      >
        <Typography
          fontSize="18px"
          fontWeight="400"
          sx={{
            color:
              text.black
          }}
        >
          {details}
        </Typography>
      </AccordionDetails>
    </Accordion>
  ))

  return (
    <Grid
      sx={{
        border: '0px solid red',
        mt: 15,
        mb: 17,
        px: {
          md: 0,
          xs: 1.5,
        },
      }}
      item
      container
    >
      <Grid
        sx={{
          border: '0px solid red',
        }}
        xs={12}
        md={6}
        item
        display="flex"
        justifyContent="center"
      >
        <Grid
          xs={12}
          md={7}
          item
        >
          <Typography
            variant="h2"
            fontWeight="900"
            sx={{
              mt: {
                md: 8,
              },
              color: primary.main,
              textAlign: {
                xs: 'center',
              },
              fontSize: {
                md: '64px',
                xs: '32px',
              },
            }}
          >
            Frequently asked questions
          </Typography>
        </Grid>
      </Grid>
      <Grid
        xs={12}
        md={5}
        sx={{
          border: '0px solid red',
          mt: {
            md: 0,
            xs: 2,
          },
        }}
        item
      >
        {renderFAQs}
      </Grid>
    </Grid>
  );
}

export default FAQs;
