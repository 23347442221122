import React from 'react';
import {
    Box,
    Dialog,
    IconButton,
    useTheme
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import YouTube from 'react-youtube';

interface Props {
    url: string;
    isOpen: boolean;
    close: () => void;
}

function YouTubePlayerModal({
    url,
    isOpen,
    close
}: Props) {

    const theme = useTheme();

    // YouTube player options
    const opts = {
        height: '90%',
        width: '100%',
        playerVars: {
            autoplay: 1,
            rel: 0
        },
    };

    if (!isOpen) return null;
    return (
        <Dialog
            open={isOpen}
            onClose={close}
            maxWidth="xl" // Adjusts the maximum width
            fullWidth={true} // Makes the dialog full width
            PaperProps={{
                sx: {
                    m: 0,
                    p: 0,
                    height: '100%', // Full height
                    width: '100%',
                    overflow: 'hidden',
                    position: 'relative',
                    backgroundColor: 'transparent' // Removes background color
                },
            }}
        >

            <Box sx={{
                textAlign: 'right',
            }}>
                <IconButton
                    aria-label="close"
                    onClick={close}
                    sx={{
                        positon: 'absolute',
                        backgroundColor: 'white',
                        color: theme.palette.grey[500],
                        zIndex: 1201, // Ensures the button is above the YouTube player
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </Box>
            <YouTube videoId={extractVideoID(url)} style={{ height: "100%", width: "100%" }} opts={opts} />
        </Dialog>
    );
}

// Helper function to extract the YouTube video ID from the URL
function extractVideoID(url: string) {
    const regExp = /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/|shorts\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/;
    const match = url.match(regExp);
    return match && match[1] ? match[1] : undefined;
}

export default YouTubePlayerModal;
